<template>
  <InitLayout>
    <b-card
      no-body
      class="d-flex flex-column align-items-center justify-content-center bg-transparent border-0 rounded-0 px-1 px-lg-4 login-container-card"
    >
      <!-- login form -->
      <form
        id="login-form"
        class="d-flex flex-column align-items-start justify-content-center px-2 py-3 py-lg-0 px-lg-1 w-100"
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <h3 class="text-left text-main-green font-primary font-weight-bold">
              Password reset
            </h3>
            <b-row
              class="d-flex flex-row flex-nowrap align-items-center justify-content-start mb-5"
              no-gutters
            >
              <span
                class="text-left text-gray-750 font-primary font-weight-normal"
              >
                We will send a link to your email to rest your passowrd
              </span>
            </b-row>
          </b-col>
        </b-row>
        <!-- full row input (Username) -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span
              class="text-left text-gray-950 font-primary font-weight-normal font-weight-bold"
              style="font-size: 0.89rem"
            >
              Enter the email address of your account
            </span>
          </b-col></b-row
        >
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <b-col cols="12" md="10">
            <FormInput
              groupId="user-name-group"
              id="user-name-group-input"
              class="text-prime-gray rounded-2"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              label="Email"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              :whiteBG="false"
              trim
              form="login-form"
              type="text"
            ></FormInput>
            <!-- @keypress.native="isEmail(email)" -->
          </b-col>
        </b-row>
        <b-row
          v-if="pwdResetError.status == true"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-danger text-small text-center">{{
              this.pwdResetError.message
            }}</span>
          </b-col>
        </b-row>
        <b-row
          v-if="pwdResetError.status == false"
          class="d-flex flex-row align-items-center justify-content-center w-100 mb-3"
          no-gutters
        >
          <b-col cols="12" md="10">
            <span class="text-success text-small text-center">{{
              this.pwdResetError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- form action -->
        <b-row
          class="d-flex flex-row align-items-center justify-content-center w-100"
          no-gutters
        >
          <!-- login button -->
          <b-col
            class="d-flex flex-column align-items-center justify-content-center px-0 py-2"
            cols="12"
            md="10"
          >
            <FormButton
              isBlock
              isPill
              class="font-weight-normal mt-4 button-link"
              variant="main-green"
              type="submit"
              :isLoading="isLoader"
              @click.native="forgotPwdFn"
              ><span>Send the link</span></FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-card>
  </InitLayout>
</template>

<script>
// services
//   import { LoginNew } from "../services/login.service";

// components
// @ is an alias to /src
import InitLayout from "@/layout/InitLayout";
import FormInput from "@/components/Form/FormInput";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";
import { ForgotPassword } from "@/services/resetPwd.service";

export default {
  name: "Login",
  components: {
    InitLayout,
    FormInput,
    FormButton,
  },
  data() {
    return {
      isLoader: false,
      email: null,
      emailError: {
        status: null,
        message: "",
      },
      pwdResetError: {
        status: null,
        message: "",
      },
    };
  },
  watch: {
    email(value) {
      this.pwdResetError.status = null;
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
  },
  methods: {
    async forgotPwdFn() {
      this.pwdResetError.status = null;
      if (this.email && CheckEmail(this.email)) {
        let payload = {
          email: this.email,
        };
        this.isLoader = true;
        ForgotPassword(payload)
          .then(async (res) => {
            if (res.data) {
              this.pwdResetError.status = false;
              this.pwdResetError.message = res.data.msg;
            }
          })
          .catch((error) => {
            console.log("error");
            console.log(error.response);
            this.pwdResetError.message = error.response
              ? error.response.data.msg
              : error.message;
            this.pwdResetError.status = true;
          })
          .finally(() => (this.isLoader = false));
      } else {
        this.validateForm();
      }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
  },
};
</script>

<style scoped>
.login-container-card {
  width: 70%;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
}

.button-link {
  border-color: var(--main-green);
  border-radius: 1.8px;
}
.button-link:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .login-container-card {
    min-height: fit-content;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-container-card {
    width: 90%;
  }
}
</style>
